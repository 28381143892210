import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';

const v2API = /\/api\/v2(.*)/;
@Injectable()
export class OrganizationHeaderInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.patch(request));
  }

  private patch(request: HttpRequest<any>) {
    const orgUuid = window.localStorage.getItem('activeOrgEZT');
    if (orgUuid && v2API.test(request.url)) {
      return request.clone({
        headers: request.headers.set('LJ-User-Organization', orgUuid as string),
      });
    }

    return request;
  }
}
