import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Pusher: any;

@Injectable()
export class PusherServiceProvider {
    channel: any;
    pusher: any;

    constructor(public http: HttpClient) {
    }

    public init(channel = '') {
        const secret = btoa(environment.pusherAuthEndpointSecret);
        this.pusher = new Pusher(environment.pusherKey, {
            cluster: environment.pusherCluster,
            encrypted: false,
            channelAuthorization: {
                endpoint: environment.pusherAuthEndpoint,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                headers: { Authorization: 'Basic ' +  secret},
            }
        });
        this.channel = this.pusher.subscribe(channel);
        return this.channel;
    }

    public disconnect() {
        if (this.pusher) {
            this.pusher.disconnect();
        }
    }
}
