import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssembleStatusService {

  mode = false;

  constructor() { }

  public update(status) {
    this.mode = status;
  }

}
