import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import SwiperCore, { Pagination, Navigation, Swiper } from 'swiper';
import {Capacitor} from '@capacitor/core';
import {environment} from '../../../environments/environment';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-item-info',
  templateUrl: './item-info.component.html',
  styleUrls: ['./item-info.component.scss'],
})
export class ItemInfoComponent implements OnInit {
  @Input() row: any = {};
  @Input() isActivePegboard = false;
  @Input() mobileView = false;
  @Input() assembledMode = false;
  @Output() clickUploadImage: EventEmitter<any> = new EventEmitter();
  @Output() clickFileUpload: EventEmitter<any> = new EventEmitter();
  @Output() clickAddNote: EventEmitter<any> = new EventEmitter();
  @Output() clickCapture: EventEmitter<any> = new EventEmitter();
  @Output() clickReportIssue: EventEmitter<any> = new EventEmitter();
  @Output() alert: EventEmitter<any> = new EventEmitter();
  @Output() clickInventoryUpdate: EventEmitter<any> = new EventEmitter();
  @Output() clickPlaceOrder: EventEmitter<any> = new EventEmitter();
  @Output() clickContribute: EventEmitter<any> = new EventEmitter();
  @Output() clickShowQR: EventEmitter<any> = new EventEmitter();
  @Output() clickGuide: EventEmitter<any> = new EventEmitter();
  @Output() callMarkAssembleFromModal: EventEmitter<any> = new EventEmitter();
  @Output() clickIFU: EventEmitter<any> = new EventEmitter();

  sharedSuccess = false;
  isDesktop = true;
  fileAllowed = ['.jpg', '.jpeg', '.png'];
  isLocate = false;
  locateTab = 0;
  createLocate = false;
  locateForm = {
    name: '',
    qty: '',
  };
  locateHistory;
  isImageUpload = false;
  qrString = '';
  isNative = Capacitor.isNativePlatform();
  isGuide = false;
  isCheckList = false;
  mdSize = 6;

  constructor(public plt: Platform, private alertCtrl: AlertController, private translateService: TranslateService) {
    if (this.plt.is('ios') || this.plt.is('android')) {
      this.isDesktop = false;
    }
  }

  showContribute(q) {
    this.clickContribute.emit(q);
  }

  uploadImage(row) {
    this.isImageUpload = false;
    this.clickUploadImage.emit(row);
  }

  fileUpload(event) {
    this.isImageUpload = false;
    if (this.isFileAllowed(this.fileAllowed, event.name)) {
        this.clickFileUpload.emit({
          preview: event.preview,
          source: event.source,
          updated: true,
          removed: false,
          data: this.row
        });
    } else {
      this.alert.emit({
        title: this.t('File not supported'),
        msg: this.t('Please use such file types:', {value: this.fileAllowed.join(', ')}),
        buttonText: this.t('Got it')
      });
    }
  }

  isFileAllowed(arr, fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = arr;
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0].toLowerCase()) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  addLocate() {
    this.createLocate = true;
    return false;
  }

  setLocateQty(i, value) {
    if (this.row.locateData[i].qty === 0 && value < 0) {
      return false;
    }
    this.row.locateData[i].qty = this.row.locateData[i].qty + value;
  }

  locateQtyIndicator(i) {
    if(this.locateHistory[i] && this.row.locateData[i] && this.locateHistory[i].qty !== this.row.locateData[i].qty) {
      if (this.row.locateData[i].qty > this.locateHistory[i].qty) {
        return 'success';
      } else {
        return 'danger';
      }
    }
    return 'primary';
  }

  placeOrder() {
    this.clickPlaceOrder.emit({order: this.row.locateData[0].order, sku: this.row.sku});
  }

  updateInventory() {
    if(this.createLocate) {
      this.row.locateData.push(
        { sku: this.row.sku, location: this.locateForm.name, qty: this.locateForm.qty, order: this.row.locateData[0].order }
      );
      this.createLocate = false;
      this.locateForm.name = '';
      this.locateForm.qty = '';
    }
    this.locateHistory = JSON.parse(JSON.stringify(this.row.locateData));
    this.clickInventoryUpdate.emit(this.row);
  }

  async updateInventoryNotify() {
    const alert = await this.alertCtrl.create({
      header: this.t('Updating inventory'),
      message: this.t('Are you sure you want to update the inventory of this item?'),
      buttons: [
        {
          text: this.t('Update Inventory'),
          handler: () => {
            this.updateInventory();
          }
        },
        {
          text: this.t('Cancel'),
          role: 'cancel',
        },
      ]
    });
    await alert.present();
  }

  async openInstImageCapture() {
    const alert = await this.alertCtrl.create({
      header: this.t('What would you like to add?'),
      message: '',
      buttons: [
        {
          text: this.t('Catalog image'),
          handler: () => {
            this.isImageUpload = true;
          }
        },
        {
          text: this.t('Images to train computer vision'),
          handler: () => {
            this.addCapture();
          }
        },
        {
          text: this.t('Cancel'),
          role: 'cancel',
        },
      ]
    });
    await alert.present();
  }

  addNote() {
    this.clickAddNote.emit(this.row);
  }

  setLocateTab(tab) {
    if(this.row.locateData.length > 0) {
      this.locateTab = tab;
    }
  }

  setLocateOrder(value) {
    if(this.row.locateData[0].order === 0 && value < 0) {
      return false;
    }
    this.row.locateData[0].order = this.row.locateData[0].order + value;
  }

  blank() {
    return false;
  }

  // share() {
  //   const link = this.isNative ? environment.auth0CallBackUrl + window.location.pathname : window.location.href;
  //   this.copyTextToClipboard(link);
  // }

  locate() {
    this.isLocate = !this.isLocate;
  }

  addCapture() {
    this.clickCapture.emit(this.row);
  }

  reportIssue() {
    this.clickReportIssue.emit(this.row);
  }

  showQR() {
    this.clickShowQR.emit(this.qrString);
  }

  startGuide() {
    this.clickGuide.emit(this.row);
  }

  /*fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
      this.setSharedSuccess();
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      this.setSharedSuccess();
    }

    document.body.removeChild(textArea);
  }

  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      this.setSharedSuccess();
    }, (err) => {
      console.error('Async: Could not copy text: ', err);
      this.setSharedSuccess();
    });
  }

  setSharedSuccess() {
    this.sharedSuccess = true;
    setTimeout(() => {
      this.sharedSuccess = false;
    }, 6000);
  }*/

  getImageTitle(row) {
    if(row.brand && row.sku) {
      return row.brand + ' ' + row.sku;
    } else if(row.brand && !row.sku) {
      return row.brand;
    } else if(row.sku && !row.brand) {
      return row.sku;
    } else {
      return '';
    }
  }

  getImagesNum(row) {
    let num = row.images.length;
    if(row.image1 || row.image2) {
      num += 1;
    }
    return num;
  }

  openIFUByAesculap(sku) {
    window.open('https://www.aesculapusaifus.com/?item=' + sku + '&category=All', '_blank');
    return false;
  }

  t(text, params = null) {
    if(params) {
      return this.translateService.instant(text, params);
    } else {
      return this.translateService.instant(text);
    }
  }

  doAssembleAction(event) {
    this.callMarkAssembleFromModal.emit(event);
  }

  openIFU(data: any) {
    this.clickIFU.emit(data);
  }


  ngOnInit() {
    this.locateHistory = JSON.parse(JSON.stringify(this.row.locateData));
    this.qrString = this.row.id + ', ' + this.row.brand + ', ' + this.row.sku;
    this.isGuide = environment.guideInstList.includes(this.row.sku);
    this.isCheckList = this.row.checklist && this.row.checklist.length > 0;
    this.mdSize = this.mobileView ? 12 : 6;
  }
}
