import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {AssembleStatusService} from '../../services/assemble-status.service';
import { ItemInfoComponent } from '../item-info/item-info.component';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import {environment} from '../../../environments/environment';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, AfterViewInit {
  @ViewChild(ItemInfoComponent) infoComponent!: ItemInfoComponent;
  @ViewChild('swiper') swiperComponent!: SwiperComponent;

  type: string;
  data: any;
  errorMessage = '';

  invite = {
    code: ''
  };

  welcomeStep = 0;
  welcomeVideo;
  welcomeNavBulls = [...new Array(7).keys()];
  catalogPhotoStatus = 0;

  markAsMissing = true;
  viewReport = true;

  assembleCallBackFunction: any;
  getInstrumentInfoForMultipleResults: any;
  clickIFU: any;
  isFirstSlide: boolean;
  isLastSlide: boolean;
  rowIndex: number;
  showSimilarItemsHeaderTitle: boolean;
  srcTrusted: any = null;
  srcURL = '';

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private domSanitizer: DomSanitizer,
    public assembled: AssembleStatusService) {
    this.type = this.navParams.get('type');
    this.data = this.navParams.get('data');
    if(this.type === 'welcome') {
      this.welcomeVideo = this.domSanitizer.bypassSecurityTrustResourceUrl(
        'https://player.vimeo.com/video/695524924?h=f75e8a1f79&badge=0&autopause=0&player_id=0&app_id=58479'
      );
    }
    if(this.type === 'previewPDF') {
      this.srcURL = environment.mediaPath + '/?file=' + encodeURIComponent(this.data.file) + '#search=' + this.data.query + '&phrase=true';
      this.srcTrusted = this.domSanitizer.bypassSecurityTrustResourceUrl(this.srcURL);
    }
  }

  submitInviteForm() {
    this.modalController.dismiss(this.invite);
  }

  async myDismiss() {
    await this.modalController.dismiss(0);
  }

  async markAsDone() {
    await this.modalController.dismiss(1);
  }

  doAssembleAction(data) {
    this.modalController.dismiss(data);
  }

  openInstrumentInfo(data, index) {
    this.modalController.dismiss({
      type: 'info',
      data,
      index,
      showSimilarItemsHeaderTitle: true
    });
  }

  backWelcome() {
    this.welcomeStep = 0;
  }

  printMat() {
    window.open('https://qr-scan.it/g/q5GaeF3OIn', '_blank');
  }

  nextWelcomeStep() {
    this.welcomeStep = this.welcomeStep + 1;
  }

  useCatalogPhoto() {
    this.catalogPhotoStatus = 1;
  }

  async takeMorePhotosLater() {
    this.data.status = 1;
    await this.modalController.dismiss(this.data);
  }

  async takeMorePhotos() {
    this.data.status = 2;
    await this.modalController.dismiss(this.data);
  }

  async retakeCatalogPhoto() {
    this.data.status = 3;
    await this.modalController.dismiss(this.data);
  }

  async submitTrayDoneForm(data) {
    await this.modalController.dismiss(data.value);
  }

  callAssembleCallBack(event) {
    this.assembleCallBackFunction({type: event, data: this.data[this.rowIndex]});
  }

  showIFU(event) {
    this.clickIFU(event);
  }

  changeSwiperSlide(action: string) {
    if(action === 'next') {
      this.rowIndex++;
      this.getInstrumentInfoForMultipleResults(this.data[this.rowIndex]);
      this.swiperComponent.swiperRef.slideNext();
    } else {
      this.rowIndex--;
      this.getInstrumentInfoForMultipleResults(this.data[this.rowIndex]);
      this.swiperComponent.swiperRef.slidePrev();
    }
    this.isFirstSlide = this.swiperComponent.swiperRef.isBeginning;
    this.isLastSlide = this.swiperComponent.swiperRef.isEnd;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    if(this.swiperComponent) {
      this.swiperComponent.swiperRef.activeIndex = this.rowIndex;
      this.isFirstSlide = this.rowIndex === 0;
      this.isLastSlide = this.rowIndex === this.data.length - 1;
    }
  }
}
