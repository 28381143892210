import {Injectable} from '@angular/core';
import {LoadingController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    isLoading = false;

    constructor(public loadingController: LoadingController) {
    }

    async present() {
      if(!this.isLoading) {
        this.isLoading = true;
        return await this.loadingController.create({
          duration: 60000,
          message: 'Please wait...',
          showBackdrop: false,
          spinner: 'crescent'
        }).then(a => {
          a.present().then(() => {
            if (!this.isLoading) {
              a.dismiss();
              this.isLoading = false;
            }
          });
        });
      }
    }

    async dismiss() {
      if(this.isLoading) {
        await this.loadingController.dismiss();
        this.isLoading = false;
      }
        /*setTimeout(() => {
            this.isLoading = false;
            this.loadingController.dismiss();
        }, 500);*/
    }
}
