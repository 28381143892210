import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ModalComponent} from '../components/modal/modal.component';
import {ModalController} from '@ionic/angular';
import {LoadingService} from '../services/loading.service';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {

  isNetworkModalOpen = false;

  constructor(private modalController: ModalController, private loading: LoadingService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!navigator.onLine) {
      this.openModal('network', {});
      setTimeout(() => {
        this.loading.dismiss();
      }, 200);
      return throwError(new Error('Connection lost'));
    }
    return next.handle(request);
  }

  async openModal(type, data) {
    if(!this.isNetworkModalOpen) {
      this.isNetworkModalOpen = true;
      const modal: HTMLIonModalElement =
        await this.modalController.create({
          component: ModalComponent,
          cssClass: 'modal-' + type,
          componentProps: {
            type,
            data
          }
        });

      modal.onDidDismiss().then((resM: any) => {
        if (type === 'network') {
          this.isNetworkModalOpen = false;
        }
      });
      await modal.present();
    }
  }

}
